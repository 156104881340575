export class Login {

    id: number;
    employeeId: number;
    companyId: number;
    branchId: number;
    loginId: string
    password: string
    name: string
    roleId: number;
    departmentId: number;
    designationId: number;
    emailId: string
    contactNo: string
    status: boolean;
    userApprovedBy: string
    emailIdApproved: string
    licence_Exp_Date:string
}