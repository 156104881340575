import swal from 'sweetalert';

export class Globle
{
    name :any;
    static urlArray;
    static id=0
    static assetId
    static invoicedata 
    static invoiceVoucherNo
   static movementId
   static maintenanceid;
   static claimNo;
   static movmentApproval =[]
   static loginArr=[]
   static loginUserDesg
   static maintenanceApproval =[]
   static salesInvId =0;
   static invoiceid
   static vendorId;
   static bankVoucherNo;
   static fileId;
   static fileIDtwo;
   static documentType="";
   static paymentType="";
   /****** */
   static imageBlobUrl
   static processId : number
   static retiredId
   static coaId=0;
   static jobCode;

   static poId;
 static grnId;
 static soid;
 static invoice; 
}

export class ReportFilter
{
    companyId:any
    branchId
    empCode
    documentType=""
    periodFrom=""
    periodTo=""
    fiscalYear=""
    costCenter
    processingStage
    file :FileReader;
    startDate : string;
    endDate : string;
    getAll : boolean;
    role
    data : any;
    allBranch : boolean;
    allCostCenter : boolean;
    userRole;
    invoiceIdArr=[];
    vendorName : string;
	ageingOn : string;
    currentDate : string;
    reportId : Number;
    customerName : string;
    custId : Number;
    msme: Number;
    glCode: any;
    paymentVoucherNo;
    voucherFrom :number;
    voucherTo :number;
    vendorFlag;
    coucherFlag;
    mode;
    voucherFlag;
    voucherfrom;
    voucherto
    vendorCode;
    paymentType;
    accountNo;
    tdsFlag;
    salesInvoiceMap ;
    invoiceList=[];
    regime


}
export class SalesUpdateInvoice{
    id;
    invValue;
    paymentType
    paymentVoucherNo
}
export class ErrorCode {
    public static getErrorCode(data: any) {
        var code = data.status;
        switch (code) {
            case 0: {
                swal("Server Not Found")
            }
                break;
            case 100: {
                swal("Continue")
            }
                break;
            case 101: {
                swal("Switching Protocols")
            }
                break;
            case 103: {
                swal("Early Hints")
            }

            case 200:
                {
                    swal("Data Successfully Submitted")
                }
                break;
            case 201:
                {
                    swal("Data Successfully Created")
                }
                break;
            case 202:
                {
                    swal("Data  Accepted Successfully")
                }
                break;
            case 203: {
                swal("Non-A,uthoritative Information")
            }
                break;
            case 204: {
                swal("No Content")
            }
                break;
            case 205: {
                swal("Reset Content")
            }
                break;
            case 206: {
                swal("Partial Content")
            }
                break;
            case 300: {
                swal("Multiple Choices")
            }
                break;
            case 301: {
                swal("Moved Permanently")
            }
                break;
            case 302: {
                swal("Found")
            }
                break;
            case 303: {
                swal(" See Other")
            }
                break;
            case 304: {
                swal(" Not Modified")
            }
                break;
            case 307: {
                swal("Temporary Redirect")
            }
                break;
            case 308: {
                swal("Permanent Redirect")
            }
                break;

            case 400: {
                swal("Bad Request")
            }
                break;
            case 401: {
                swal("Unauthorized")
            }
                break;
            case 402: {
                swal("Payment Required")
            }
                break;
            case 403: {
                swal("Forbidden")
            }
                break;
            case 404: {
                swal("Not Found")
            }
                break;
            case 405: {
                swal("Method Not Allowed")
            }
                break;
            case 406: {
                swal("Not Acceptable")
            }
                break;
            case 407: {
                swal("Proxy Authentication Required")
            }
                break;
            case 408: {
                swal("Request Timeout")
            }
                break;
            case 409: {
                swal("Conflict")
            }
                break;
            case 410: {
                swal("Gone")
            }
                break;
            case 411: {
                swal("Length Required")
            }
                break;
            case 412: {
                swal("Precondition Failed")
            }
                break;
            case 413: {
                swal("Payload Too Large")
            }
                break;
            case 414: {
                swal("URI Too Long")
            }
                break;
            case 415: {
                swal("Unsupported Media Type")
            }
                break;
            case 416: {
                swal("Range Not Satisfiable")
            }
                break;
            case 417: {
                swal("Expectation Failed")
            }
                break;
            case 418: {
                swal("I m a teapot")
            }
                break;
            case 422: {
                swal("Unprocessable Entity")
            }
                break;
            case 425: {
                swal("Too Early")
            }
                break;
            case 426: {
                swal("Upgrade Required")
            }
                break;
            case 428: {
                swal("Precondition Required")
            }
                break;
            case 429: {
                swal("Too Many Requests")
            }
                break;
            case 431: {
                swal("Request Header Fields Too Large")
            }
                break;
            case 451: {
                swal(" Unavailable For Legal Reasons")
            }
                break;
            case 500:
                {
                    swal("Internal Server Error")
                }
                break;
            case 501:
                {
                    swal("Not Implemented")
                }
                break;
            case 502:
                {
                    swal("Bad Gateway")
                }
                break;
            case 503:
                {
                    swal("Service Unavailable")
                }
                break;
            case 504:
                {
                    swal("Gateway Timeout")
                }
                break;
            case 505:
                {
                    swal("HTTP Version Not Supported")
                }
                break;
            case 506:
                {
                    swal("Variant Also Negotiates")
                }
                break;
            case 507:
                {
                    swal("Insufficient Storage")
                }
                break;
            case 508:
                {
                    swal("Loop Detected")
                }
                break;
            case 510:
                {
                    swal("Not Extended")
                }
                break;
            case 511:
                {
                    swal("Network Authentication Required")
                }
                break;



        }



    }
}
