

export class Userlogin
{
   id: Number;
   employeeId: Number;//int
   companyId: Number;//int
   branchId: Number;//int
   branchCode;
   companyCode;
   loginId: string;
   password: string;
   name: string;
   roleId : Number;//int
   departmentId: Number;//int
   designationId: Number;//int
   emailId: string;
   contactNo: string;
   status: Number;
   userApprovedBy: string;
   emailIdApproved: string;
   createdBy : string;
   updatedBy : string;
   invoiceCountLimit:Number;
   invoiceLimitStatus :boolean;
   
}
