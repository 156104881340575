import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    © 2022-23, Powered by A F Solutions
    </span> Test E-Invoice V 1.3(AS)
  `,
})
export class FooterComponent {
  //last updated date = 28-12-2022
}
