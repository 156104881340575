import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { DataService } from '../services/data.service';
// import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/loginService';
import { Login } from '../models/login';

import { Role } from '../models/role';
import { Company } from '../models/company';
import { ReportFilter, Globle } from '../Globle';
import { NbToastrService } from '@nebular/theme';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { UserloginService } from '../services/userloginService';
import { Userlogin } from '../models/userlogin';



@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


 
  ngOnInit() { }
  role: Role;
  username: any = '';
  password: any = '';
  companyId: any = '';
  loginData: any
  loginForm: any;
  loginCheck = false;
  public errorMessage: string = '';
  customSpinner: boolean = false;
  alertBox: boolean = false;
  locales = [];
  currentUrl = "";
  login: Login = new Login();
  companyArr: Company[];
  validate: boolean;
  userArr: Login[];
  today

  reportFilter: ReportFilter = new ReportFilter();
  constructor(private fb: FormBuilder,
    private route: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private loginService: LoginService,private datePipe : DatePipe ) {
    // sessionStorage.clear();
    this.loginForm = fb.group({
      user: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      companyId: ['', Validators.required]
    });
 
  }
  msg: any;
  resp;
  get f() { return this.loginForm.controls; }

  async userLogin() {
    // // console.log("sdasdgb")
    //  //this.toastr.error( 'Login Failed!');
    //  //this.ngxService.start();
    // this.route.navigate(['/pages/welcome']);
    try {
      //  if (this.validate) {
      this.username = this.loginForm.value.user;
      this.password = this.loginForm.value.password;
      this.companyId = this.loginForm.value.companyId
      //this.loginData = { "username": this.username, "password": this.password }
      this.login.loginId = this.username;
      this.login.password = this.password;

      this.loginService.userLogin(this.companyId, this.login.loginId, this.login.password).subscribe(response => {
        this.resp = response;


        console.log("response ", this.resp);
        sessionStorage.setItem('payload', JSON.stringify(this.resp));

        this.today = new Date().toISOString().split('T')[0];
        if (this.resp.length > 0 && this.resp[0].status != false) {
          console.log("this.resp[0].licence_Exp_Date ", this.resp[0].licence_Exp_Date ,this.today);
          console.log( this.today< this.datePipe.transform(this.resp[0].licence_Exp_Date, 'yyyy-MM-dd') ? true : false);
        
          if ( this.today<= this.datePipe.transform(this.resp[0].licence_Exp_Date, 'yyyy-MM-dd') ? true : false) {
            if(this.resp[0].invoiceLimitStatus){    
                      let myPayload = JSON.parse(sessionStorage.getItem('payload'));
           
            console.log("rolid sss", myPayload[0].roleId)

            this.reportFilter.userRole = myPayload[0].roleId
            this.loginService.getForms(this.reportFilter).subscribe(response => {
              sessionStorage.setItem('forms', JSON.stringify(response));
              // this.checkOnLogin(myPayload[0].companyId,myPayload[0].employeeId);
              console.log(response)
              //this.route.navigate(['/pages/task/taskmanager']);
              this.ngxService.stop();
              this.route.navigate(['/pages/welcome']);
              this.reportFilter.userRole = 0;
            })
          }else{
            alert("Invoice Limit Exceeded")
          }
          } else {
            alert("Your License Has Expired !!")
          }
        }
        else {
          alert("Invalid Credentials")
          //this.ngxService.stop();
          //this.toastr.error('Login Failed!');
        }
      })

    }
    catch (e) {
      console.log(e);
    }
    finally {

    }
  }


  getCompany() {
    var utc = new Date().toJSON().slice(0, 10)
    let comp = parseInt(this.loginForm.value.companyId);
    console.log("comp", comp);

    this.loginService.searchCompanyId(comp).subscribe(response => {
      this.companyArr = response
      if (this.companyArr.length > 0){
        if (utc < this.companyArr[0].licence_Exp_Date) {
          this.validate = true;
        }
        else {
          alert("Company Licence is Expired")
          this.validate = false
        }
       }else if (this.companyArr.length == 0) {
        alert("Company Code is Invalid")
      }
    })

  }

  getLoginId() {
    var utc = new Date().toJSON().slice(0, 10)
    let userId = this.loginForm.value.user
    this.loginService.searchLoginId(userId).subscribe(response => {
      this.userArr = response;
      if (this.userArr.length > 0)
        if (this.userArr[0].status == false) {
          alert("LoginId is Inactive")
          this.validate = false;
        }
        else {
          this.validate = true;
        }

      if (this.userArr.length == 0) {
        alert("LoginId is Invalid")
        this.validate = false 
      }
    })

  }
  // movementArr
  // checkOnLogin(companyId,employeeId){
  //   console.log("companyId ",companyId,"  employeeId  ",employeeId)
  //   this.loginService.checkOnLogin(companyId,employeeId).subscribe(
  //     data =>{
  //       this.movementArr = data
  //       console.log("in checkOnLogin movementArr ",this.movementArr)
  //       if(this.movementArr.length>0){
  //         ApproveMovementComponent.loginArr = this.movementArr;
  //         this.route.navigate(['/pages/movement-maintenance/appMov']);
  //       }
  //       else{
  //         this.route.navigate(['/pages/welcome']);
  //       }
  //     }
  //   )
  // }


}
