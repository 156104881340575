import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserloginService } from '../../../services/userloginService';
import { Userlogin } from '../../../models/userlogin';
import { CompanyService } from '../../../services/companyService';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
// export class HeaderComponent implements OnInit, OnDestroy {

//   private destroy$: Subject<void> = new Subject<void>();
//   userPictureOnly: boolean = false;
//   user: any;

//   themes = [
//     {
//       value: 'default',
//       name: 'Light',
//     },
//     {
//       value: 'dark',
//       name: 'Dark',
//     },
//     {
//       value: 'cosmic',
//       name: 'Cosmic',
//     },
//     {
//       value: 'corporate',
//       name: 'Corporate',
//     },
//   ];

//   currentTheme = 'default';

//   userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

//   constructor(private sidebarService: NbSidebarService,
//               private menuService: NbMenuService,
//               private themeService: NbThemeService,
//               private userService: UserData,
//               private layoutService: LayoutService,
//               private breakpointService: NbMediaBreakpointsService) {
//   }

//   ngOnInit() {
//     this.currentTheme = this.themeService.currentTheme;

//     this.userService.getUsers()
//       .pipe(takeUntil(this.destroy$))
//       .subscribe((users: any) => this.user = users.nick);

//     const { xl } = this.breakpointService.getBreakpointsMap();
//     this.themeService.onMediaQueryChange()
//       .pipe(
//         map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
//         takeUntil(this.destroy$),
//       )
//       .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

//     this.themeService.onThemeChange()
//       .pipe(
//         map(({ name }) => name),
//         takeUntil(this.destroy$),
//       )
//       .subscribe(themeName => this.currentTheme = themeName);
//   }

//   ngOnDestroy() {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }

//   changeTheme(themeName: string) {
//     this.themeService.changeTheme(themeName);
//   }

//   toggleSidebar(): boolean {
//     this.sidebarService.toggle(true, 'menu-sidebar');
//     this.layoutService.changeLayoutSize();

//     return false;
//   }

//   navigateHome() {
//     this.menuService.navigateHome();
//     return false;
//   }
// }

export class HeaderComponent implements OnInit, OnDestroy {

  currentTheme = 'default';
  logout: any;
  userPicture = "../../../../assets/images/no-user.png";
  userMenu = [{ title: 'Logout' }];

  constructor(private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService,
    private route: Router,private _location: Location,
    private userLoginService :UserloginService,private companyService: CompanyService, private sanitizer: DomSanitizer) {
      

  }
  userName
  loginId
  companyCode
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    //this.userName = sessionStorage.getItem('userName');
    this.logout = this.menuService.onItemClick()
      .subscribe((event) => {
        if (event.item.title === 'Logout') {
          sessionStorage.clear();
          console.log("logout");
          this.route.navigate(['/login']);
        }
      });
      var payload
      payload = JSON.parse(sessionStorage.getItem('payload'));
      if(payload !=null){
    
      this.userName = payload[0].name
      this.loginId = payload[0].loginId;
      this.companyCode = payload[0].companyCode;
      this.currentTheme = this.themeService.currentTheme;
    
      this.fetchCompany();
      }
  }

  ngOnDestroy() {
    this.logOut();
    this.logout.unsubscribe();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  navigateToTms(){
    window.open("http://tms.mymonthlycharge.com")
  }
  backClicked() {
    this._location.back();
  }
 

  logOut() {

    var login = new Userlogin;
    login.companyCode = this.companyCode;
    login.loginId =  this.loginId;
    this.userLoginService.logOut(login).subscribe(
      data => {
        console.log(data);

      }
    )
  }

  goToHome() {
    this.route.navigate(['/pages/welcome']);
  }

  img
  fetchCompany() {
    this.companyService.searchCompany(this.companyCode).subscribe(
      data => {
        //  //console.log(data);
        var companyLogo
         companyLogo = data
        // //console.log("logo ",companyLogo);

        console.log(companyLogo);

        let objectURL = 'data:image/jpeg;base64,' + companyLogo[0].image;

        this.img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
         console.log(this.img);

      }
    )
  }



}
